import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ButtonGroup,
    Icon,
    IconButton,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { FiTrash2 } from 'react-icons/fi';

interface Props {
    preAddon?: React.ReactNode;
    viewComponent?: React.ReactNode;
    editComponent?: React.ReactNode;
    deleteAction?: () => void;
    entryType: string;
    entryIdentifier: string;
}

export function ListEntryButtons({
    preAddon,
    viewComponent,
    editComponent,
    deleteAction,
    entryType,
    entryIdentifier,
}: Props): ReactElement {
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);

    const confirm = () => {
        deleteAction?.();
        onClose();
    };

    return (
        <>
            <ButtonGroup>
                {preAddon}
                {viewComponent}
                {editComponent}
                {deleteAction && (
                    <IconButton
                        colorScheme="red"
                        onClick={() => setIsOpen(true)}
                        icon={<Icon as={FiTrash2} />}
                        aria-label="Delete"
                    />
                )}
            </ButtonGroup>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete {entryType}?
                        </AlertDialogHeader>

                        <AlertDialogBody>{entryIdentifier}</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirm} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}
