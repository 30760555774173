import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { authContext } from '../../../../stores';
import { API } from '../../../../utils';
import { CreatableSelect, Select } from '../../../forms';
import { FilterOption, FilterOptionProps } from '../useFilters';

export interface SelectFilterOption extends FilterOption {
    type: 'select';
    options: string;
    creatable?: boolean;
    multiple?: boolean;
}

interface SelectOption {
    label: string;
    value: string;
}

export function SelectFilterOptionInput({
    field,
    option,
    value,
    setValue,
}: FilterOptionProps<SelectFilterOption>): React.ReactElement {
    const { state } = useContext(authContext);
    const { isLoading, data, refetch } = useQuery(
        `selectFilterOptions${field}`,
        () => {
            return new Promise<SelectOption[] | undefined>((resolve) => {
                API.get<SelectOption[]>(
                    option.options,
                    {
                        success: (options) => {
                            resolve(options);
                        },
                    },
                    state.token
                );
            });
        },
        {
            enabled: false, // Only manually refetching in onFocus or when the value is changed
        }
    );

    useEffect(() => {
        if (!data && value?.trim().length > 0) {
            refetch();
        }
    }, [data, value]);

    const UsedSelect = option.creatable ? CreatableSelect : Select;
    const controlHandlers = option.multiple
        ? {
              onChange: (value: SelectOption[]) => {
                  setValue(value?.map((o) => o.value).join(','));
              },
              value: value
                  ?.split(',')
                  .map((v) => data?.find((o) => o.value === v)),
          }
        : {
              onChange: (value: SelectOption) => {
                  setValue(value?.value);
              },
              value: data?.find((o) => o.value === value),
          };
    return (
        <UsedSelect
            options={data}
            placeholder={option.label}
            isMulti={option.multiple}
            isClearable
            closeMenuOnSelect={!option.multiple}
            {...controlHandlers}
            isLoading={isLoading}
            onFocus={refetch}
        />
    );
}
