import { ErrorPage } from 'common/pages';
import React, { lazy } from 'react';
import {
    FiBell,
    FiBriefcase,
    FiCamera,
    FiCreditCard,
    FiDollarSign,
    FiDownloadCloud,
    FiFileText,
    FiFolderPlus,
    FiGrid,
    FiHome,
    FiLock,
    FiMessageSquare,
    FiPackage,
    FiServer,
    FiSettings,
    FiTag,
    FiUsers,
} from 'react-icons/fi';
import { Redirect } from 'react-router-dom';
import { APIRole } from './constants';

export enum RouteID {
    LOGIN = 'login',
    SETUP = 'setup',
    DASHBOARD = 'dashboard',

    LIST_ACCOUNT = 'listAccounts',

    VIEW_MOD = 'viewMod',
    LIST_MOD = 'listMods',

    LIST_COSMETICS = 'listCosmetics',

    LIST_REPORTS = 'listReports',

    VIEW_REPORT = 'viewReport',
}

export const ROUTES: { [key: string]: App.Route } = {
    login: {
        type: 'page',
        path: '/auth/login',
        page: lazy(() => import('../pages/auth/login')),
        breadcrumb: 'Login',
    },
    setup: {
        type: 'page',
        path: '/account/setup',
        page: lazy(() => import('../pages/accounts/setup')),
        conditions: [loggedIn, hasRole(APIRole.NEW)],
        breadcrumb: 'Setup',
    },

    dashboard: {
        type: 'page',
        path: '/',
        exact: true,
        page: lazy(() => import('../pages')),
        breadcrumb: 'Dashboard',
        nav: true,
        icon: FiHome,
        conditions: [loggedIn, hasRole(APIRole.NEW, APIRole.USER)],
    },

    listAccounts: {
        type: 'page',
        path: '/accounts',
        page: lazy(() => import('../pages/accounts/list')),
        breadcrumb: 'Accounts',
        nav: true,
        icon: FiUsers,
        conditions: [loggedIn, hasRole(APIRole.ACCOUNTS)],
    },

    viewMod: {
        type: 'page',
        path: '/mods/view/:id',
        page: lazy(() => import('../pages/mods/view')),
        breadcrumb: 'View Mod',
        conditions: [loggedIn, hasRole(APIRole.MODS)],
    },
    listMods: {
        type: 'page',
        path: '/mods',
        page: lazy(() => import('../pages/mods/list')),
        breadcrumb: 'Mods',
        nav: true,
        icon: FiGrid,
        conditions: [loggedIn, hasRole(APIRole.MODS)],
    },

    cosmetics: {
        type: 'submenu',
        path: '/cosmetics',
        breadcrumb: 'Cosmetics',
        nav: true,
        icon: FiPackage,
        conditions: [loggedIn, hasRole(APIRole.COSMETICS)],
        subMenu: {
            listCosmetics: {
                type: 'page',
                path: '/cosmetics',
                page: lazy(() => import('../pages/cosmetics/list')),
                breadcrumb: 'List',
                nav: true,
                exact: true,
                conditions: [loggedIn, hasRole(APIRole.COSMETICS)],
            },

            cosmeticBundles: {
                type: 'page',
                path: '/cosmetics/bundles',
                page: lazy(() => import('../pages/cosmetics/bundles')),
                breadcrumb: 'Bundles',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.COSMETICS)],
            },

            cosmeticTypes: {
                type: 'page',
                path: '/cosmetics/types',
                page: lazy(() => import('../pages/cosmetics/types')),
                breadcrumb: 'Types',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.COSMETICS)],
            },

            cosmeticCategories: {
                type: 'page',
                path: '/cosmetics/categories',
                page: lazy(() => import('../pages/cosmetics/categories')),
                breadcrumb: 'Categories',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.COSMETICS)],
            },
        },
    },

    listServers: {
        type: 'page',
        path: '/servers',
        page: lazy(() => import('../pages/servers/list')),
        breadcrumb: 'Servers',
        nav: true,
        icon: FiServer,
        conditions: [loggedIn, hasRole(APIRole.SERVERS)],
    },

    viewReport: {
        type: 'page',
        path: '/chat/reports/view/:id',
        page: lazy(() => import('../pages/reports/view')),
        breadcrumb: 'View Report',
        conditions: [loggedIn, hasRole(APIRole.REPORTS)],
    },
    chat: {
        type: 'submenu',
        path: '/chat',
        breadcrumb: 'Chat',
        nav: true,
        icon: FiMessageSquare,
        conditions: [loggedIn, hasRole(APIRole.REPORTS, APIRole.ANNOUNCEMENTS)],
        subMenu: {
            listAnnouncements: {
                type: 'page',
                path: '/chat/announcements',
                page: lazy(() => import('../pages/announcements/list')),
                breadcrumb: 'Announcements',
                nav: true,
                exact: true,
                conditions: [loggedIn, hasRole(APIRole.ANNOUNCEMENTS)],
            },
            listReports: {
                type: 'page',
                path: '/chat/reports',
                page: lazy(() => import('../pages/reports/list')),
                breadcrumb: 'Reports',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.REPORTS)],
            },
        },
    },

    transactions: {
        type: 'page',
        path: '/transactions',
        page: lazy(() => import('../pages/transactions/list')),
        breadcrumb: 'Transactions',
        nav: true,
        icon: FiCreditCard,
        conditions: [loggedIn, hasRole(APIRole.TRANSACTIONS)],
    },

    sales: {
        type: 'page',
        path: '/sales',
        page: lazy(() => import('../pages/sales/list')),
        breadcrumb: 'Sales',
        nav: true,
        icon: FiTag,
        conditions: [loggedIn, hasRole(APIRole.SALES)],
    },

    gdpr: {
        type: 'page',
        path: '/gdpr',
        page: lazy(() => import('../pages/gdpr')),
        breadcrumb: 'GDPR',
        nav: true,
        icon: FiLock,
        conditions: [loggedIn, hasRole(APIRole.GDPR)],
    },

    playerCoins: {
        type: 'page',
        path: '/player-coins',
        page: lazy(() => import('../pages/player-coins/page')),
        breadcrumb: 'Player Coins',
        nav: true,
        icon: FiDollarSign,
        conditions: [loggedIn, hasRole(APIRole.NEW, APIRole.USER)],
    },

    media: {
        type: 'page',
        path: '/media',
        page: lazy(() => import('../pages/media/list')),
        breadcrumb: 'Media',
        nav: true,
        icon: FiCamera,
        conditions: [loggedIn, hasRole(APIRole.MEDIA)],
    },

    listNotices: {
        type: 'page',
        path: '/notices',
        page: lazy(() => import('../pages/notices/list')),
        breadcrumb: 'Notices',
        nav: true,
        icon: FiBell,
        conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
    },

    partners: {
        type: 'submenu',
        path: '/partners',
        breadcrumb: 'Partners',
        nav: true,
        icon: FiBriefcase,
        conditions: [loggedIn, hasRole(APIRole.PARTNERS)],
        subMenu: {
            codes: {
                type: 'page',
                path: '/partners/codes',
                page: lazy(() => import('../pages/partners/codes/list')),
                breadcrumb: 'Codes',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.PARTNERCODES)],
            },
            organizations: {
                type: 'page',
                path: '/partners/organizations',
                page: lazy(
                    () => import('../pages/partners/organizations/list')
                ),
                breadcrumb: 'Organizations',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.PARTNERS)],
            },
            users: {
                type: 'page',
                path: '/partners/users',
                page: lazy(() => import('../pages/partners/users/list')),
                breadcrumb: 'Users',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.PARTNERS)],
            },
            revenueSummary: {
                type: 'page',
                path: '/partners/revenue/summary',
                page: lazy(() => import('../pages/revenue/summary')),
                breadcrumb: 'Revenue Summary',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.FINANCES)],
            },
        },
    },

    trustedHosts: {
        type: 'page',
        path: '/trusted-hosts',
        page: lazy(() => import('../pages/trusted-hosts/list')),
        breadcrumb: 'Trusted Hosts',
        nav: true,
        icon: FiDownloadCloud,
        conditions: [loggedIn, hasRole(APIRole.TRUSTEDHOSTS)],
    },

    installer: {
        type: 'submenu',
        path: '/installer',
        breadcrumb: 'Installer',
        nav: true,
        icon: FiFolderPlus,
        conditions: [loggedIn, hasRole(APIRole.INSTALLER)],
        subMenu: {
            listVersions: {
                type: 'page',
                path: '/installer/versions',
                page: lazy(() => import('../pages/installer/versions')),
                breadcrumb: 'Versions',
                nav: true,
                exact: true,
                conditions: [loggedIn, hasRole(APIRole.INSTALLER)],
            },
            listWrappers: {
                type: 'page',
                path: '/installer/wrappers',
                page: lazy(() => import('../pages/installer/wrappers')),
                breadcrumb: 'Wrappers',
                nav: true,
                exact: true,
                conditions: [loggedIn, hasRole(APIRole.INSTALLER)],
            },
        },
    },

    configuration: {
        type: 'submenu',
        path: '/configuration',
        breadcrumb: 'Configuration',
        nav: true,
        icon: FiSettings,
        conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
        subMenu: {
            launcherVersions: {
                type: 'page',
                path: '/configuration/launcher-versions',
                page: lazy(() => import('../pages/launcher-versions/list')),
                breadcrumb: 'Launcher Versions',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
            },
            platforms: {
                type: 'page',
                path: '/configuration/platforms',
                page: lazy(() => import('../pages/platforms/list')),
                breadcrumb: 'Platforms',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
            },
            protocolVersions: {
                type: 'page',
                path: '/configuration/protocol-versions',
                page: lazy(() => import('../pages/protocol-versions/list')),
                breadcrumb: 'Protocol Versions',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
            },
            telemetry: {
                type: 'page',
                path: '/configuration/telemetry',
                page: lazy(() => import('../pages/telemetry/list')),
                breadcrumb: 'Telemetry Configurations',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
            },
            documents: {
                type: 'page',
                path: '/configuration/documents',
                page: lazy(() => import('../pages/documents/list')),
                breadcrumb: 'Documents',
                nav: true,
                conditions: [loggedIn, hasRole(APIRole.CONFIGURATION)],
            },
        },
    },

    auditLog: {
        type: 'page',
        path: '/audit-log',
        page: lazy(() => import('../pages/audit-log/list')),
        breadcrumb: 'Audit Log',
        nav: true,
        icon: FiFileText,
        conditions: [loggedIn, hasRole(APIRole.AUDIT)],
    },
};

function loggedIn(
    route: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if (!user) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.LOGIN].path} />,
        };
    }
    if (
        route.path !== ROUTES[RouteID.SETUP].path &&
        (user as User.AdminUser)?.roles.includes(APIRole.NEW)
    ) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.SETUP].path} />,
        };
    }
    return { success: true };
}

function hasRole(...roles: APIRole[]) {
    return function _(
        _: App.BaseRoute,
        user?: User.BaseUser
    ): App.ConditionCheckResult {
        if (
            roles &&
            user &&
            !roles.some((role) => (user as User.AdminUser).roles.includes(role))
        ) {
            return {
                success: false,
                renderComponent: <ErrorPage code={404} />,
            };
        }

        return { success: true };
    };
}
