import { Box, Stack, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type StatEntry = {
    name: string;
    value: string;
};

interface Props {
    stats: StatEntry[];
}

export function StatStack({ stats }: Props): ReactElement {
    const statComponents = stats.map((stat, idx) => (
        <Stat key={idx}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <StatLabel>{stat.name}</StatLabel>
                <StatNumber>{stat.value}</StatNumber>
            </Box>
        </Stat>
    ));
    return (
        <Stack
            direction={['column', 'column', 'row']}
            overflowX="auto"
            border="1px solid #ccc"
            borderRadius="16px"
            padding="3"
        >
            {statComponents}
        </Stack>
    );
}
