import {
    Box,
    Divider,
    Heading,
    ListIcon,
    ListItem,
    Text,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons/lib';
import { BorderBox } from './BorderBox';

interface PanelProps {
    title: string;
    children: React.ReactNode;
}

export function Panel({ title, children }: PanelProps): ReactElement {
    return (
        <BorderBox
            display="flex"
            flexDirection="column"
            flexBasis={0}
            flexGrow={1}
        >
            <Heading textAlign="center" size="md">
                {title}
            </Heading>
            <Divider my={1} />
            <Box display="flex" flexGrow={1} alignItems="center">
                {children}
            </Box>
        </BorderBox>
    );
}

interface PanelItemProps {
    icon: IconType;
    name: string;
    value: React.ReactNode;
}

export function PanelListItem({
    icon,
    name,
    value,
}: PanelItemProps): ReactElement {
    return (
        <ListItem>
            <ListIcon as={icon} />
            <Text display="inline-block" fontWeight="600">
                {name}
            </Text>
            : <Box float="right">{value}</Box>
        </ListItem>
    );
}
