/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO workaround for this
/* eslint-disable @typescript-eslint/ban-types */
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Icon,
    IconButton,
    IconButtonProps,
    Skeleton,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { authContext } from '../../stores/AuthStore';
import { API } from '../../utils/api';
import { getToastOptions } from '../../utils/toast';

interface Props<D> {
    row?: D;
    edit?: boolean;
    createApiRoute: string;
    modifyApiRoute: (id: D) => string;
    name: string;
    Form: React.ComponentType<Form.ModifyFormProps<D> & any>;
    onCreate?: (data: D) => void;
    onModify?: () => void;
    buttonSize?: IconButtonProps['size'];
}

export function ModifyDrawer<D, F = object>({
    row,
    edit,
    createApiRoute,
    modifyApiRoute,
    name,
    Form,
    onCreate,
    onModify,
    buttonSize = 'md',
    ...formProps
}: Props<D> & F): ReactElement {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { state } = useContext(authContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<D | undefined>();
    const [validationErrors, setValidationErrors] =
        useState<Form.ValidationErrors>({});
    const toast = useToast();

    useEffect(() => {
        let controller: AbortController;
        if (isOpen && edit && row) {
            controller = API.get<D>(
                modifyApiRoute(row),
                {
                    success: (data) => {
                        setData(data);
                        setValidationErrors({});
                    },
                },
                state.token
            );
        }

        return () => {
            controller?.abort();
        };
    }, [isOpen, edit, state.token, row, modifyApiRoute]);

    // Clear data after closed
    useEffect(() => {
        if (!isOpen && !!data) {
            setData(undefined);
            setValidationErrors({});
        }
    }, [isOpen, data]);

    const submit = async (data: D) => {
        setLoading(true);

        // Filter out empty values
        Object.keys(data).forEach((key) => {
            if (data[key] === '' || data[key] == null) {
                delete data[key];
            }
        });

        const method = edit ? API.put : API.post;
        const endpoint = edit && row ? modifyApiRoute(row) : createApiRoute;

        return method<D, D>(
            endpoint,
            data,
            {
                success: (res) => {
                    toast(
                        getToastOptions(
                            'success',
                            `Successfully ${
                                edit ? 'updated' : 'created'
                            } ${name}`
                        )
                    );
                    setLoading(false);
                    onClose();

                    if (res) onCreate?.(res);
                    onModify?.();
                },

                validation: (err) => {
                    setLoading(false);
                    setValidationErrors(err);
                },
            },
            state.token
        );
    };

    return (
        <>
            <IconButton
                as={Box}
                aria-label={edit ? 'Edit' : 'Create'}
                icon={edit ? <Icon as={FiEdit} /> : <Icon as={FiPlus} />}
                colorScheme={edit ? 'yellow' : 'green'}
                cursor="pointer"
                onClick={(e) => {
                    e.preventDefault();
                    onOpen();
                }}
                ml={edit ? 0 : 3}
                size={buttonSize}
            />
            <Drawer
                isOpen={isOpen}
                placement="top"
                onClose={onClose}
                size="full"
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            {edit ? 'Edit' : 'Create'} {name}
                        </DrawerHeader>

                        <DrawerBody>
                            <Skeleton isLoaded={!edit || !!data}>
                                <Form
                                    data={data}
                                    submit={submit}
                                    cancel={onClose}
                                    errors={validationErrors}
                                    buttonText={edit ? 'Save' : 'Create'}
                                    loading={loading}
                                    {...formProps}
                                />
                            </Skeleton>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
}
