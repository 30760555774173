/* eslint-disable @typescript-eslint/ban-types */
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Column, TableInstance, useTable } from 'react-table';

interface Props<D extends object> {
    columns: Column<D>[];
    data?: D[];
}

export function DataTable<D extends object>({
    columns,
    data,
}: Props<D>): ReactElement {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    }: TableInstance<D> = useTable({ columns, data: data || [] });

    return (
        <Table {...getTableProps()} width="100%">
            <Thead>
                {headerGroups.map((headerGroup, idx) => (
                    <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                        {headerGroup.headers.map((column, idx) => (
                            <Th
                                {...column.getHeaderProps()}
                                key={idx}
                                textAlign={
                                    idx === headerGroup.headers.length - 1
                                        ? 'right'
                                        : 'left'
                                }
                            >
                                {column.render('Header')}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {rows.map((row, idx) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()} key={idx}>
                            {row.cells.map((cell, idx) => (
                                <Td
                                    {...cell.getCellProps()}
                                    textAlign={
                                        idx === row.cells.length - 1
                                            ? 'right'
                                            : 'left'
                                    }
                                    key={idx}
                                >
                                    {cell.render('Cell')}
                                </Td>
                            ))}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}
