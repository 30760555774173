import { Box, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import React from 'react';
import { FilterOptions } from './useFilters';

type Props = {
    options: FilterOptions;
    filters: Record<string, string>;
    removeFilter: (field: string) => void;
};

export default function FilterBadges({
    options,
    filters,
    removeFilter,
}: Props): React.ReactElement {
    return (
        <Box my={3}>
            {Object.keys(filters).map((fieldName) => {
                const option = options[fieldName];
                return (
                    <Tag mr={2} key={fieldName} colorScheme="purple">
                        <TagLabel>
                            {option.label}: {filters[fieldName]}
                        </TagLabel>
                        <TagCloseButton
                            onClick={() => removeFilter(fieldName)}
                        />
                    </Tag>
                );
            })}
        </Box>
    );
}
