import { Center, Flex } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    children: React.ReactNode;
}

export function ErrorLayout({ children }: Props): ReactElement<Props> {
    return (
        <Center minHeight="100vh" w="100vw">
            <Flex width="full" align="center" justifyContent="center" m={3}>
                {children}
            </Flex>
        </Center>
    );
}
