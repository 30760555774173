import {
    AccordionItem,
    AccordionItemProps,
    AccordionPanel,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { DetailHeading } from './DetailHeading';

interface Props {
    title: React.ReactNode;
    children: React.ReactNode;
}

export function DetailBox({
    title,
    children,
    ...props
}: Props &
    Omit<Partial<AccordionItemProps>, 'title' | 'children'>): ReactElement {
    return (
        <AccordionItem {...props}>
            <DetailHeading title={title} />

            <AccordionPanel>{children}</AccordionPanel>
        </AccordionItem>
    );
}
