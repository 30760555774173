import { Box, Checkbox } from '@chakra-ui/react';
import React from 'react';
import { FilterOption, FilterOptionProps } from '../useFilters';

export interface BooleanFilterOption extends FilterOption {
    type: 'boolean';
}

export default function BooleanFilterOptionInput({
    option,
    value,
    setValue,
}: FilterOptionProps<BooleanFilterOption>): React.ReactElement {
    return (
        <Box
            width="100%"
            textAlign="center"
            border="1px solid"
            borderRadius="var(--chakra-radii-md)"
            borderColor="var(--chakra-colors-gray-200)"
            display="flex"
            justifyContent="center"
            height="var(--chakra-sizes-10)"
        >
            <Checkbox
                isChecked={value !== undefined ? value === 'true' : false}
                onChange={(e) => {
                    setValue(
                        e.target.checked === false
                            ? undefined
                            : String(e.target.checked)
                    );
                }}
            >
                {option.label}
            </Checkbox>
        </Box>
    );
}
