import { Icon, IconButton } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { FiEye } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface Props {
    link: string;
}

export function ViewButton({ link }: Props): ReactElement {
    return (
        <IconButton
            colorScheme="blue"
            as={Link}
            to={link}
            icon={<Icon as={FiEye} />}
            aria-label="View"
        />
    );
}
