import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    colors: {
        primary: '#' + process.env.REACT_APP_THEME_COLOR,
        green: {
            '50': '#ECFAEB',
            '100': '#CAF0C7',
            '200': '#A7E6A3',
            '300': '#85DC7F',
            '400': '#62D35A',
            '500': '#40C936',
            '600': '#33A12B',
            '700': '#267821',
            '800': '#1A5016',
            '900': '#0D280B',
        },
        red: {
            '50': '#FAEBEB',
            '100': '#F0C7C7',
            '200': '#E6A3A3',
            '300': '#DC7F7F',
            '400': '#D35A5A',
            '500': '#C93636',
            '600': '#A12B2B',
            '700': '#782121',
            '800': '#501616',
            '900': '#280B0B',
        },
        orange: {
            '50': '#FBF2E9',
            '100': '#F4DAC2',
            '200': '#EDC29C',
            '300': '#E6AB75',
            '400': '#DF934E',
            '500': '#D87B27',
            '600': '#AD621F',
            '700': '#824A17',
            '800': '#563110',
            '900': '#2B1908',
        },
        yellow: {
            '50': '#FCFAE8',
            '100': '#F7F2BF',
            '200': '#F2EA96',
            '300': '#EEE16D',
            '400': '#E9D944',
            '500': '#E4D01B',
            '600': '#B6A716',
            '700': '#897D10',
            '800': '#5B530B',
            '900': '#2E2A05',
        },
        blue: {
            '50': '#EBF0FA',
            '100': '#C7D4F0',
            '200': '#A3B8E6',
            '300': '#7F9CDC',
            '400': '#5A81D3',
            '500': '#3665C9',
            '600': '#2B51A1',
            '700': '#213D78',
            '800': '#162850',
            '900': '#0B1428',
        },
        purple: {
            '50': '#F4EBFA',
            '100': '#E1C7F0',
            '200': '#CEA3E6',
            '300': '#BB7FDC',
            '400': '#A85AD3',
            '500': '#9536C9',
            '600': '#772BA1',
            '700': '#592178',
            '800': '#3B1650',
            '900': '#1E0B28',
        },
        pink: {
            '50': '#F8EBFA',
            '100': '#ECC7F0',
            '200': '#DFA3E6',
            '300': '#D37FDC',
            '400': '#C65AD3',
            '500': '#BA36C9',
            '600': '#942BA1',
            '700': '#6F2178',
            '800': '#4A1650',
            '900': '#250B28',
        },
        cyan: {
            '50': '#EBF9FA',
            '100': '#C7EDF0',
            '200': '#A3E2E6',
            '300': '#7FD7DC',
            '400': '#5ACBD3',
            '500': '#36C0C9',
            '600': '#2B9AA1',
            '700': '#217378',
            '800': '#164D50',
            '900': '#0B2628',
        },
        teal: {
            '50': '#EBFAF6',
            '100': '#C7F0E6',
            '200': '#A3E6D7',
            '300': '#7FDCC7',
            '400': '#5AD3B7',
            '500': '#36C9A7',
            '600': '#2BA186',
            '700': '#217864',
            '800': '#165043',
            '900': '#0B2821',
        },
    },
    fonts: {
        body: "'Inter', sans-serif",
        heading: "'Roboto', sans-serif",
    },
});
