import {
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { FiInfo } from 'react-icons/fi';
import { FilterOption, FilterOptionProps } from '../useFilters';

type MetaTypeResolver = (
    props: FilterOptionProps<TextFilterOption>
) => React.ReactElement;

type MetaTypes = {
    [key: string]: MetaTypeResolver;
};

const metaTypes: MetaTypes = {
    search: ({
        option,
        value,
        setValue,
    }: FilterOptionProps<TextFilterOption>) => (
        <InputGroup>
            <Input
                placeholder={option.label}
                value={value}
                onChange={(e) =>
                    setValue(
                        e.target.value?.trim().length === 0
                            ? undefined
                            : e.target.value.trim()
                    )
                }
            />
            <InputRightElement>
                <Tooltip
                    label='Supports "POSIX shell globbing" patterns'
                    fontSize="md"
                >
                    <span>
                        <Icon as={FiInfo} />
                    </span>
                </Tooltip>
            </InputRightElement>
        </InputGroup>
    ),
};

export interface TextFilterOption extends FilterOption {
    type: 'text';
    metaType?: string;
}

export function registerMetaType(
    key: string,
    resolver: MetaTypeResolver
): void {
    metaTypes[key] = resolver;
}

export function TextFilterOptionInput(
    props: FilterOptionProps<TextFilterOption>
): React.ReactElement {
    const { option, value, setValue } = props;

    if (option.metaType) {
        if (!metaTypes[option.metaType]) {
            throw new Error(
                'Unknown text filter meta type: ' + option.metaType
            );
        }

        return metaTypes[option.metaType](props);
    }

    return (
        <Input
            placeholder={option.label}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}
