import { LoadingSpinner } from 'common/components';
import { authContext } from 'common/stores';
import { getRoutes } from 'common/utils';
import React, { lazy, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ROUTES } from './utils/routes';

export default function MainApp(): React.ReactElement {
    const { state } = useContext(authContext);

    // Check for auth state loading
    if (state.isLoading) {
        return <LoadingSpinner />;
    }

    // Assemble routes
    const routes = getRoutes(ROUTES, state);

    const ErrorPage = lazy(() =>
        import('common/pages/ErrorPage').then((module) => ({
            default: module.ErrorPage,
        }))
    );
    return (
        <Router>
            <ErrorBoundary fallback={<ErrorPage code={500} />}>
                <Suspense fallback={<LoadingSpinner />}>
                    <Switch>
                        {routes}

                        <Route>
                            <ErrorPage code={404} />
                        </Route>
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        </Router>
    );
}
