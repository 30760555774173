export const COOKIE_KEY = 'authToken';

type IdentifiedRow = {
    _id: string;
};

export const API_ROUTES = {
    auth: {
        login: '/v1/auth/login',
    },

    configuration: (subPath: string): string => `/v1/configuration/${subPath}`,

    account: {
        user: '/v1/account/me',
        setup: '/v1/account/setup',

        list: '/v1/accounts',
        modify: (row: User.AdminUser): string => `/v1/accounts/${row._id}`,
        getOne: (id: string): string => `/v1/accounts/${id}`,
    },

    announcements: {
        list: (channelId: string): string => `/v1/announcements/${channelId}`,
        modify: (channelId: string, id: string): string =>
            `/v1/announcements/${channelId}/${id}`,
    },

    mod: {
        list: '/v1/mods',
        modify: (row: IdentifiedRow): string => `/v1/mods/${row._id}`,
    },

    update: {
        list: (modId: string): string => `/v1/mods/${modId}/updates`,
        modify: (modId: string, id: string): string =>
            `/v1/mods/${modId}/updates/${id}`,
        branches: (modId: string): string =>
            `/v1/mods/${modId}/update-branches`,
        publishers: (modId: string): string => `/v1/mods/${modId}/publishers`,
        publish: (modId: string, id: string): string =>
            `/v1/mods/${modId}/updates/${id}/publish`,
    },

    cosmetic: {
        list: '/v1/cosmetics',
        modify: (row: Cosmetic.Cosmetic): string => `/v1/cosmetics/${row._id}`,
        listTypes: '/v1/cosmetic-types',
        modifyTypes: (row: Cosmetic.Type): string =>
            `/v1/cosmetic-types/${row._id}`,
        listBundles: '/v1/cosmetic-bundles',
        modifyBundles: (row: Cosmetic.Bundle): string =>
            `/v1/cosmetic-bundles/${row._id}`,
        listCategories: '/v1/cosmetic-categories',
        modifyCategories: (row: Cosmetic.Category): string =>
            `/v1/cosmetic-categories/${row._id}`,
        categoryOrder: `/v1/order/cosmetic-categories`,
        categoryCosmeticsOrder: (id: string): string =>
            `/v1/order/cosmetic-categories/${id}`,
        refresh: '/v1/cosmetics/refresh',
        updateCache: {
            cosmetics: '/v1/cosmetics/update/cache',
            types: '/v1/cosmetic-types/update/cache',
            bundles: '/v1/cosmetic-bundles/update/cache',
        },
        refreshTypes: '/v1/cosmetic-types/refresh',
        getTebex: (packageId: string): string => `/v1/tebex/${packageId}`,
    },

    document: {
        list: '/v1/documents',
        modify: (id: string): string => `/v1/documents/${id}`,
    },

    launcherVersion: {
        list: '/v1/launcher-versions',
        modify: (row: Launcher.Version): string =>
            `/v1/launcher-versions/${row._id}`,
    },

    protocolVersion: {
        list: '/v1/protocol-versions',
        modify: (row: Protocol.Version): string =>
            `/v1/protocol-versions/${row._id}`,
    },

    platform: {
        list: '/v1/platforms',
        modify: (row: Platform.Platform): string => `/v1/platforms/${row._id}`,
    },

    media: {
        list: '/v1/media',
        modify: (row: Media.Item): string =>
            `/v1/media/${row._id}?uploaded_by=${row.created.by}`,
    },

    files: {
        presignedUrl: (contentType: string, fileName: string): string =>
            `/v1/files/presigned-url/upload?contentType=${contentType}&fileName=${fileName}`,
        presignedAssetUrl: (contentType: string, fileName: string): string =>
            `/v1/files/presigned-url/assets?contentType=${contentType}&fileName=${fileName}`,
    },

    metrics: {
        basic: '/v1/metrics/basic',
    },

    servers: {
        list: '/v1/servers',
        modify: (row: Servers.Server): string => `/v1/servers/${row._id}`,
    },

    reports: {
        list: '/v1/reports',
        modify: (row: IdentifiedRow): string => `/v1/reports/${row._id}`,
        messages: (id: string): string => `/v1/reports/${id}/messages`,
        resolve: (id: string): string => `/v1/reports/${id}/resolve`,
    },

    gdpr: (uuid: string): string => `/v1/gdpr/${uuid}`,

    trustedHost: {
        list: '/v1/trusted-hosts',
        modify: (row: TrustedHosts.Configuration): string =>
            `/v1/trusted-hosts/${row._id}`,
    },

    notices: {
        list: '/v1/notices',
        modify: (row: Notices.Notice): string => `/v1/notices/${row._id}`,
    },

    installerVersions: {
        list: '/v1/installer-versions',
        modify: (version: Installer.Version): string =>
            `/v1/installer-versions/${version._id}`,
        branches: `/v1/installer-versions/branches`,
    },

    installerWrappers: {
        list: '/v1/installer-wrappers',
        modify: (wrapper: Installer.Wrapper): string =>
            `/v1/installer-wrappers/${wrapper._id}`,
        branches: `/v1/installer-wrappers/branches`,
    },

    players: {
        lookup: {
            uuid: (uuid: string): string => `/v1/users/lookup/uuid/${uuid}`,
            userName: (userName: string): string =>
                `/v1/users/lookup/username/${userName}`,
            mojang: {
                uuid: (uuid: string): string =>
                    `/v1/users/lookup/mojang/uuid/${uuid}`,
                userName: (userName: string): string =>
                    `/v1/users/lookup/mojang/username/${userName}`,
            },
        },
    },

    transactions: {
        list: '/v1/transactions',
        modify: (row: Transactions.Transaction): string =>
            `/v1/transactions/${row._id}`,
    },

    sales: {
        list: '/v1/sales',
        modify: (row: Sales.Sale): string => `/v1/sales/${row._id}`,
    },

    revenue: {
        summary: '/v1/revenue/distribution-summary',
    },

    playerCoins: {
        get: (uuid: string): string => `/v1/player-coins/${uuid}`,
    },

    partners: {
        codes: {
            list: '/v1/partner-codes',
            modify: (row: Partners.Code): string =>
                `/v1/partner-codes/${row._id}`,
        },

        organizations: {
            list: '/v1/organizations',
            modify: (row: Partners.Organization): string =>
                `/v1/organizations/${row._id}`,
        },

        users: {
            list: '/v1/partner-users',
            modify: (row: Partners.User): string =>
                `/v1/partner-users/${row._id}`,

            clearTOS: '/v1/partner-users/tos/clear',
        },
    },

    auditLog: {
        list: '/v1/audit-log',
    },

    telemetry: {
        list: '/v1/telemetry',
        modify: (row: Telemetry.Configuration): string =>
            `/v1/telemetry/${row._id}`,
    },

    tebexExport: {
        process: '/v1/tebex-export-process',
    },
};

export enum APIRole {
    NEW = 'NEW',
    USER = 'USER',
    ACCOUNTS = 'ACCOUNTS',
    FINANCES = 'FINANCES',
    MODS = 'MODS',
    CONFIGURATION = 'CONFIGURATION',
    COSMETICS = 'COSMETICS',
    GDPR = 'GDPR',
    MEDIA = 'MEDIA',
    SERVERS = 'SERVERS',
    REPORTS = 'REPORTS',
    TRANSACTIONS = 'TRANSACTIONS',
    PARTNERS = 'PARTNERS',
    AUDIT = 'AUDIT',
    ANNOUNCEMENTS = 'ANNOUNCEMENTS',
    TRUSTEDHOSTS = 'TRUSTEDHOSTS',
    INSTALLER = 'INSTALLER',
    PARTNERCODES = 'PARTNERCODES',
    SALES = 'SALES',
}

export enum ConfigurationType {
    LANGUAGE = 'languages',
    CURRENCY = 'currencies',
    PLATFORM = 'platforms',
    PACKAGES = 'packages',
    VISIBILITY = 'visibilities',
    PROTOCOLVERSION = 'protocol-versions',
    PARTNERROLES = 'partner-roles',
    PARTNERORGANIZATIONS = 'partner-organizations',
    ANNOUNCEMENTCHANNELS = 'announcement-channels',
    FILENAMEPLACEHOLDERS = 'types/filename-placeholders',
    ASSETTYPE = 'types/assets',
    LINKTYPE = 'types/links',
    DISTRIBUTION = 'types/distributions',
    PLATFORMTYPE = 'types/platforms',
    PLATFORMVISIBILITY = 'types/platform-visibilities',
    COSMETICS = 'cosmetics',
    COSMETICTYPE = 'types/cosmetics',
    COSMETICCATEGORY = 'types/cosmetics-categories',
    REPORTVERDICTS = 'types/report-verdicts',
    NOTICETYPES = 'types/notices',
    COSMETICSLOT = 'types/cosmetics/slots',
    REPORTSTATUSTYPE = 'types/report-status',
    TRANSACTIONDISCOUNT = 'types/transactions/discounts',
    TRANSACTIONSTATUS = 'types/transactions/statuses',
    INSTALLERPLATFORMS = 'types/installer/platforms',
    INSTALLERFILETYPES = 'types/installer/files',
    TELEMETRYDATASOURCES = 'types/telemetry/datasources',
    TRANSACTIONTYPE = 'types/transactions/type',
}

export enum MetricType {
    PLAYERS_ONLINE = 'PLAYERS_ONLINE',
    TOTAL_MODS = 'TOTAL_MODS',
    TOTAL_PARTNERS = 'TOTAL_PARTNERS',
    TOTAL_COSMETICS = 'TOTAL_COSMETICS',
}
