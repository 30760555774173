import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    title: string;
    message: string;
}

export function InfoBanner({ title, message }: Props): ReactElement {
    return (
        <Alert status="info" mb={2} borderRadius="15px">
            <AlertIcon />
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
        </Alert>
    );
}
