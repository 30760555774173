import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export interface ConfirmationAction {
    name: string;
    colorScheme?: string;
    action: () => void;
}

interface Props {
    title: string;
    description?: string;
    actions: ConfirmationAction[];

    isOpen: boolean;
    close: () => void;
}

export function ConfirmationModal({
    title,
    description,
    actions,
    isOpen,
    close,
}: Props): ReactElement {
    return (
        <Modal isOpen={isOpen} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                {description && <ModalBody>{description}</ModalBody>}

                <ModalFooter>
                    <Stack direction="row" align="center" width="full">
                        {actions.map((action, idx) => (
                            <Button
                                key={idx}
                                colorScheme={action.colorScheme}
                                onClick={action.action}
                                width="full"
                            >
                                {action.name}
                            </Button>
                        ))}
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
