import React, { useContext, useEffect, useRef, useState } from 'react';
import { authContext } from '../../stores';
import { API } from '../../utils';
import { Pagination } from '../tables';
import { FilterOptions, useFilters } from './filtering/useFilters';
import { SortingOption, useSorting } from './sorting/useSorting';

export interface UseGenericListProps {
    apiPath: string;
}

export interface UseGenericListHook<D> {
    data: D[] | undefined;
    refresh: () => void;
    paginationElement: React.ReactElement;
    filtersButton: React.ReactElement;
    filtersBadges: React.ReactElement;
    sortingButton: React.ReactElement;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useGenericList<D extends object>({
    apiPath,
}: UseGenericListProps): UseGenericListHook<D> {
    const { state } = useContext(authContext);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);

    const [data, setData] = useState<D[]>();
    const [total, setTotal] = useState(0);

    const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
    const { filtersButton, filtersBadges, filters } = useFilters({
        filterOptions,
    });

    const [sortingOptions, setSortingOptions] = useState<SortingOption[]>([]);
    const { sortingButton, sortingConfig } = useSorting({
        sortingOptions,
        defaultOption:
            sortingOptions.find((o) => o.defaultDirection !== undefined) ||
            sortingOptions[0],
    });

    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollTo = () =>
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });

    // (Re)load data
    useEffect(() => {
        const controller = refresh();

        return () => {
            controller.abort();
        };
    }, [state.token, limit, skip, apiPath, filters, sortingConfig]);

    const refresh = () => {
        setData(undefined);
        return API.getWithParams<API.PaginatedResponse<D>>(
            apiPath,
            {
                skip,
                limit,
                ...filters,
                ...sortingConfig,
            },
            {
                success: (data, res) => {
                    setFilterOptions(
                        JSON.parse(
                            res?.headers?.get('X-Filter-Options') || '{}'
                        ) as FilterOptions
                    );
                    setSortingOptions(
                        JSON.parse(
                            res?.headers?.get('X-Sorting-Options') || '[]'
                        ) as SortingOption[]
                    );

                    setTotal(data?.total || 0);
                    setData(data?.data);
                    scrollTo();
                },
            },
            state.token
        );
    };

    const paginationElement = (
        <Pagination
            totalDocuments={total}
            limit={limit}
            skip={skip}
            setLimit={setLimit}
            setSkip={setSkip}
            scrollRef={scrollRef}
        />
    );

    return {
        data,
        refresh,
        paginationElement,
        filtersButton,
        filtersBadges,
        sortingButton,
    };
}
