import React from 'react';
import { Route } from 'react-router-dom';
import { ConditionRoute } from '../components';
import { AuthState } from '../stores';

export function getRoutes(
    routes: { [key: string]: App.Route },
    state: AuthState
): React.ReactNode[] {
    return Object.entries(routes).map(([id, route]) => {
        const UsedRoute = route.conditions ? ConditionRoute : Route;

        const routes: React.ReactNode[] =
            route.type === 'page'
                ? [
                      <UsedRoute
                          key={id}
                          path={route.path}
                          exact={route.exact}
                          route={route}
                      >
                          <route.page {...route.pageProps} />
                      </UsedRoute>,
                  ]
                : [];

        if (route.type === 'submenu') {
            routes.push(...getRoutes(route.subMenu, state));
        }

        return routes;
    });
}
