import { Box, Heading, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ErrorLayout } from '../layouts';

interface Props {
    code: number;
}

export function ErrorPage({ code }: Props): ReactElement<Props> {
    return (
        <ErrorLayout>
            <Box>
                <Heading as="h1" mb="2">
                    Error {code} - Page not found.
                </Heading>
                <Text mt="2" width="100%" textAlign="center">
                    <Link to="/">Back to safety</Link>
                </Text>
            </Box>
        </ErrorLayout>
    );
}
