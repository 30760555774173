import {
    Flex,
    Icon,
    IconButton,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Text,
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import {
    FiChevronLeft,
    FiChevronRight,
    FiChevronsLeft,
    FiChevronsRight,
} from 'react-icons/fi';

interface Props {
    totalDocuments: number;
    skip: number;
    limit: number;
    setSkip: (skip: number) => void;
    setLimit: (limit: number) => void;
    scrollRef: React.Ref<HTMLDivElement>;
}

export function Pagination({
    totalDocuments,
    skip,
    limit,
    setSkip,
    setLimit,
    scrollRef,
}: Props): ReactElement {
    const [gotoVal, setGotoVal] = useState(0);

    const page = Math.ceil(skip / limit) + 1;
    const totalPages = Math.ceil(totalDocuments / limit);
    const canPreviousPage = skip > 0;
    const canNextPage = skip + limit < totalDocuments;

    const goToPage = (page: number) => {
        setSkip((page - 1) * limit);
    };

    const previousPage = () => {
        setSkip(Math.max(skip - limit, 0));
    };

    const nextPage = () => {
        setSkip(skip + limit);
    };

    return (
        <Flex
            justifyContent="space-between"
            my={4}
            alignItems="center"
            ref={scrollRef}
        >
            <Flex>
                <IconButton
                    aria-label="First page"
                    onClick={() => goToPage(1)}
                    isDisabled={!canPreviousPage}
                    icon={<Icon as={FiChevronsLeft} h={6} w={6} />}
                    mr={4}
                />
                <IconButton
                    aria-label="Previous page"
                    onClick={previousPage}
                    isDisabled={!canPreviousPage}
                    icon={<Icon as={FiChevronLeft} h={6} w={6} />}
                />
            </Flex>

            <Flex alignItems="center">
                <Text flexShrink={0} mr={8}>
                    Page{' '}
                    <Text fontWeight="bold" as="span">
                        {page}
                    </Text>{' '}
                    of{' '}
                    <Text fontWeight="bold" as="span">
                        {totalPages}
                    </Text>
                </Text>
                <Text flexShrink={0}>Go to page:</Text>{' '}
                <NumberInput
                    ml={2}
                    mr={8}
                    w={28}
                    min={1}
                    max={totalPages}
                    onBlur={() => goToPage(gotoVal)}
                    onChange={(value) => {
                        setGotoVal(Number(value) || 0);
                    }}
                    defaultValue={page}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                <Select
                    w={32}
                    value={limit}
                    onChange={(e) => {
                        setLimit(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Flex>
                <IconButton
                    aria-label="Next page"
                    onClick={nextPage}
                    isDisabled={!canNextPage}
                    icon={<Icon as={FiChevronRight} h={6} w={6} />}
                />
                <IconButton
                    aria-label="Last page"
                    onClick={() => goToPage(totalPages)}
                    isDisabled={!canNextPage}
                    icon={<Icon as={FiChevronsRight} h={6} w={6} />}
                    ml={4}
                />
            </Flex>
        </Flex>
    );
}
