import { AccordionButton, AccordionIcon, Box, Heading } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    title: React.ReactNode;
}

export function DetailHeading({ title }: Props): ReactElement {
    return (
        <h2>
            <AccordionButton>
                <Box flex="1" textAlign="left">
                    <Heading size="sm">{title}</Heading>
                </Box>
                <AccordionIcon />
            </AccordionButton>
        </h2>
    );
}
