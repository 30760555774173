// eslint-disable-next-line @typescript-eslint/no-var-requires
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/roboto/700.css';
import { AuthStateProvider, ConfigurationStateProvider } from 'common/stores';
import { getToken } from 'common/utils';
import { checkVersion } from 'common/utils/version';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { API_ROUTES, COOKIE_KEY } from 'utils/constants';
import App from './App';
import './base.scss';
import customTheme from './utils/custom-theme';

window.commonConstants = {
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000',
    cookieKey: COOKIE_KEY,
    customTheme,
};

checkVersion();

const queryClient = new QueryClient();
ReactDOM.render(
    <ErrorBoundary fallback={<span>An error occured</span>}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={customTheme}>
                    <CSSReset />
                    <AuthStateProvider
                        apiUserRoute={API_ROUTES.account.user}
                        initialState={{ token: getToken() }}
                    >
                        <ConfigurationStateProvider
                            getRoute={API_ROUTES.configuration}
                        >
                            <App />
                        </ConfigurationStateProvider>
                    </AuthStateProvider>
                </ChakraProvider>
            </QueryClientProvider>
        </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root')
);
