import { Text } from '@chakra-ui/react';
import React from 'react';

export function textToHTML(text: string): React.ReactNode {
    return text.split('\n').map((text, idx) => (
        <Text key={idx} minHeight="1rem">
            {text}
        </Text>
    ));
}
