/* eslint-disable @typescript-eslint/ban-types */
import { Box, Center, Heading, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';
import { Column } from 'react-table';
import { DataTable } from '../tables';

type GenericListProps<D extends object> = {
    data: D[] | undefined;
    title?: string;
    columns: Column<D>[];
    paginationElement: React.ReactNode;
    headerAddon?: React.ReactNode;
    headerRightAddon?: React.ReactNode;
    filtersButton?: React.ReactElement;
    filtersBadges?: React.ReactElement;
    sortingButton?: React.ReactElement;
};

export function GenericList<D extends object>({
    title,
    columns,
    headerAddon,
    headerRightAddon,
    filtersButton,
    filtersBadges,
    sortingButton,
    paginationElement,
    data,
}: GenericListProps<D>): React.ReactElement {
    const heading = title && (
        <>
            <Box
                display="flex"
                flexDir="row"
                alignItems="center"
                flexWrap="wrap"
            >
                <Heading mb={5} mt={4} size="lg">
                    {title}
                </Heading>
                <Box
                    ml={[0, 3]}
                    display="flex"
                    width={['100%', 'unset']}
                    flexDir={['column', 'row']}
                    alignItems={['left', 'center']}
                    flexWrap="wrap"
                    gap={3}
                >
                    {headerAddon}
                    {filtersButton}
                    {sortingButton}
                    {headerRightAddon && (
                        <>
                            <Box
                                display="inline-block"
                                height={['0', 'var(--chakra-fontSizes-3xl)']}
                                borderLeft={['none', '2px solid lightgray']}
                                borderTop={['2px solid lightgray', 'none']}
                            />
                            {headerRightAddon}
                        </>
                    )}
                </Box>
            </Box>
            {filtersBadges}
        </>
    );

    return data?.length === 0 ? (
        <>
            {heading}
            <Center>
                <Text>Nothing found</Text>
            </Center>
        </>
    ) : (
        <>
            {heading}
            <Skeleton isLoaded={!!data}>
                <DataTable<D> columns={columns} data={data} />
                {paginationElement}
            </Skeleton>
        </>
    );
}
