import { Box } from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { ToggleNavButton } from '../components';

interface Props {
    headerElements?: React.ReactNode;
    navLinks: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    children: React.ReactNode;
}

export function SidebarLayout({
    headerElements,
    navLinks,
    breadcrumbs,
    children,
}: Props): ReactElement {
    const [navOpen, setNavOpen] = useState(false);

    const displayNav = navOpen ? 'flex' : 'none';
    return (
        <Box
            display="flex"
            minHeight="100vh"
            flex="1 1 auto"
            flexDirection="column"
        >
            <Box
                padding="10px 20px"
                bg="primary"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ToggleNavButton
                    isOpen={navOpen}
                    toggle={() => setNavOpen(!navOpen)}
                />
                {headerElements}
            </Box>

            <Box
                display="flex"
                flex="1 1 auto"
                flexDirection={['column', 'column', 'row']}
            >
                <Box
                    display={[displayNav, displayNav, 'flex']}
                    flexDir="column"
                    flex={['0', '0', '0 0 260px']}
                    background="gray.700"
                >
                    {navLinks}
                </Box>

                <Box flex="1 1 auto" padding="15px 20px" overflowX="auto">
                    {breadcrumbs}
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
