import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Icon,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import FilterBadges from './FilterBadges';
import BooleanFilterOptionInput, {
    BooleanFilterOption,
} from './options/BooleanFilterOption';
import {
    SelectFilterOption,
    SelectFilterOptionInput,
} from './options/SelectFilterOption';
import {
    TextFilterOption,
    TextFilterOptionInput,
} from './options/TextFilterOption';

export interface FilterOption {
    type: string;
    label: string;
}

export interface FilterOptions {
    [key: string]: FilterOption;
}

export interface FilterOptionProps<O> {
    field: string;
    option: O;
    value: string;
    setValue: (value?: string) => void;
}

type SetFilterValue = (
    field: string
) => (value?: string) => Record<string, string>;

type UseFiltersProps = {
    filterOptions: FilterOptions;
};

type UseFiltersHook = {
    filtersButton: React.ReactElement;
    filtersBadges: React.ReactElement;
    filters: Record<string, string>;
};

export function useFilters({ filterOptions }: UseFiltersProps): UseFiltersHook {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filters, setFilters] = useState({});
    const [innerFilters, setInnerFilters] = useState<Record<string, string>>(
        {}
    );

    const applyFilters = (filters = innerFilters) => {
        setFilters(filters);
        onClose();
    };

    const setFilterValue: SetFilterValue =
        (field: string) => (value?: string) => {
            let newFilters;
            if (value === undefined) {
                newFilters = Object.assign({}, innerFilters);
                delete newFilters[field];
            } else {
                newFilters = {
                    ...innerFilters,
                    [field]: value,
                };
            }
            setInnerFilters(newFilters);

            return newFilters;
        };

    const removeFilter = (field: string) => {
        applyFilters(setFilterValue(field)(undefined));
    };

    const filtersButton: React.ReactElement =
        Object.keys(filterOptions).length > 0 ? (
            <>
                <Button
                    onClick={onOpen}
                    colorScheme="purple"
                    leftIcon={<Icon as={FiFilter} />}
                    aria-label="Filters"
                >
                    {Object.keys(innerFilters).length}
                </Button>
                <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Filters</DrawerHeader>

                        <DrawerBody
                            display="flex"
                            flexDir="column"
                            style={{ gap: '10px' }}
                        >
                            {Object.keys(filterOptions).map((fieldName) => {
                                const option = filterOptions[fieldName];
                                const commonOptions = {
                                    key: fieldName,
                                    field: fieldName,
                                    value: innerFilters?.[fieldName],
                                    setValue: setFilterValue(fieldName),
                                };
                                switch (option.type) {
                                    case 'boolean':
                                        return (
                                            <BooleanFilterOptionInput
                                                option={
                                                    option as BooleanFilterOption
                                                }
                                                {...commonOptions}
                                            />
                                        );
                                    case 'text':
                                        return (
                                            <TextFilterOptionInput
                                                option={
                                                    option as TextFilterOption
                                                }
                                                {...commonOptions}
                                            />
                                        );
                                    case 'select':
                                        return (
                                            <SelectFilterOptionInput
                                                option={
                                                    option as SelectFilterOption
                                                }
                                                {...commonOptions}
                                            />
                                        );
                                    default:
                                        throw new Error(
                                            'Unknown filter option: ' +
                                                option.type
                                        );
                                }
                            })}
                        </DrawerBody>

                        <DrawerFooter>
                            <Button
                                colorScheme="red"
                                mr={2}
                                onClick={() => {
                                    setInnerFilters({});
                                    applyFilters({});
                                }}
                                width="100%"
                            >
                                Reset
                            </Button>
                            <Button
                                colorScheme="blue"
                                width="100%"
                                onClick={() => applyFilters()}
                            >
                                Apply
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        ) : (
            <></>
        );

    const filtersBadges = (
        <FilterBadges
            options={filterOptions}
            filters={filters}
            removeFilter={removeFilter}
        />
    );

    return { filtersButton, filtersBadges, filters };
}
